import * as cookies from 'browser-cookies';
export class Utils {
  static convertToWebp(event: any) {
    //console.log('convert', event + '.webp')
    // return event ? URL.createObjectURL(event) : "default.png";

    if (event) {
      return event + '.webp'
    }
  }

  public static getLogo(type: any) {
    switch(type){
      case 'OFFICE365':
        return 'assets/images/connectors/office365.svg';
      case 'SPOTLIGHT':
        return 'assets/images/connectors/SpotLight.svg';
      case 'SHAREPOINT':
        return 'assets/images/connectors/SharePoint.png'
    }
  }
  private static accessTokenTo16ByteHash(accessToken: string): string {
    return CryptoJS.SHA256(accessToken).toString(CryptoJS.enc.Hex).substring(0, 32); // Get 16 bytes (32 hex characters)
  }
  public static encryptData(value: any): string {
    const token = cookies.get('access_token');
    if (!token) throw new Error('Access token is not available');

    const secretKey = Utils.accessTokenTo16ByteHash(token);
    const iv = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), CryptoJS.enc.Utf8.parse(secretKey), {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return JSON.stringify({
      iv: iv,
      data: encrypted.ciphertext.toString(CryptoJS.enc.Base64),
    });
  }

  public static decryptData(encryptedValue: string): any {
    const token = cookies.get('access_token');
    if (!token) throw new Error('Access token is not available');

    const encrypted = JSON.parse(encryptedValue);
    const secretKey = Utils.accessTokenTo16ByteHash(token);
    const iv = CryptoJS.enc.Hex.parse(encrypted.iv);
    const encryptedData = CryptoJS.enc.Base64.parse(encrypted.data);

    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: encryptedData,
      iv: iv,
      key: CryptoJS.enc.Utf8.parse(secretKey),
      padding: CryptoJS.pad.Pkcs7,
    });

    const decrypted = CryptoJS.AES.decrypt(cipherParams, CryptoJS.enc.Utf8.parse(secretKey), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }
  static sanitizeInput(input: string): string {
    const sanitizedInput = input?.replace(/[^\w\s._+-]/g, '');
    return sanitizedInput ? sanitizedInput : '';
  }
  
  static sanitizeRequestId(input: string): string {
    const sanitizedInput = input?.replace(/[^A-Za-z0-9\-]/g, '');
    return sanitizedInput ? sanitizedInput : '';
  }
  static sanitizeRedirectUrl(url: string): string {
    // Preserve common URL characters and remove any unexpected characters
    const sanitizedUrl = url?.replace(/[^a-zA-Z0-9._~:/?#&=+-]/g, '');
    return sanitizedUrl ? sanitizedUrl : '';
}

}